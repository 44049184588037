<template>
  <div>
    <b-modal
      id="cart-modal"
      title="Resumen de productos seleccionados"
      class="modal-content"
      hide-footer
    >
      <Cart :editType="'inventory'" />
    </b-modal>

    <!-- <PostalCodeModal :onSubmit="handlePostalCodeSubmit" /> -->
    <store-product-inventory-modal
      :product="selectedProduct"
      :storeProduct="selectedStoreProduct"
      @onSubmit="handleAddToCart"
    />
    <b-overlay :show="loading" rounded="sm">
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        layout="horizontal"
        finish-button-text="Confirmar"
        next-button-text="Siguiente"
        back-button-text="Regresar"
        class="wizard-vertical mb-3"
        shape="square"
        step-size="sm"
        @on-complete="formSubmitted"
      >
        <tab-content title="Tipo de pedido" :before-change="validateRestock">
          <validation-observer ref="restockRules" tag="form">
            <div>
              <h4>Información del pedido</h4>

              <div class="my-2" v-if="warehouses.length > 0">
                <h5>¿Deseas comprarle a tu almacén?</h5>

                <validation-provider
                  name="Almacén"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="wantOwnStore"
                    :options="warehouseOptions"
                    :reduce="(val) => val.id"
                    label="name"
                    placeholder="Selecciona una opcion"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <div class="my-2" v-if="wantOwnStore && warehouses.length > 1">
                <h5>¿Selecciona un almacén?</h5>

                <validation-provider
                  name="Almacén"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="selectedStoreId"
                    :options="warehouses"
                    :reduce="(val) => val.id"
                    label="name"
                    placeholder="Selecciona un almacén"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <!-- <div class="my-2" v-if="wantOwnStore === false">
                    <h5>¿Deseas comprarle a una tienda en particular?</h5>
      
                    <v-select
                      id="v-store"
                      v-model="selectedStoreId"
                      :options="ownStores"
                      :reduce="(val) => val.id"
                      label="name"
                      placeholder="Selecciona una tienda"
                    />
                  </div> -->
            </div>

            <div class="my-2">
              <h5 class="mb-2">Tipo de pedido</h5>
              <validation-provider
                name="Tipo de pedido"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="btn-group-toggle d-flex" data-toggle="buttons">
                  <label
                    v-for="typeOption in automatic_restock_type_options"
                    :key="typeOption.type"
                    class="btn btn-secondary rounded-1 btn-size"
                    :class="{
                      active:
                        automatic_restocking.automatic_restock_type ===
                        typeOption.type,
                    }"
                  >
                    <input
                      type="radio"
                      :value="typeOption.type"
                      v-model="automatic_restocking.automatic_restock_type"
                    />
                    <span
                      class="
                        d-flex
                        align-items-center
                        justify-content-center
                        h-100
                      "
                    >
                      {{ typeOption.name }}
                    </span>
                  </label>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div>
              <small
                v-if="automatic_restocking.automatic_restock_type === 'by_date'"
                class="text-warning"
              >
                El proveedor recibirá tu pedido un día antes de la fecha de
                entrega para los días seleccionados
              </small>
              <small
                v-if="
                  automatic_restocking.automatic_restock_type === 'by_stock'
                "
                class="text-warning"
              >
                El proveedor recibirá el pedido cuando tu inventario esté por
                debajo del nivel que tú indiques
              </small>
            </div>

            <h5 class="my-2">Tipo de entrega</h5>
            <validation-provider
              name="Tipo de entrega"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="btn-group-toggle d-flex" data-toggle="buttons">
                <label
                  v-for="typeOption in restock_schedule_type_options"
                  :key="typeOption.type"
                  class="btn btn-secondary rounded-1 btn-size"
                  :class="{ active: restock_schedule === typeOption.type }"
                >
                  <input
                    type="radio"
                    :value="typeOption.type"
                    v-model="restock_schedule"
                  />
                  <span
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      h-100
                    "
                  >
                    {{ typeOption.name }}
                  </span>
                </label>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <!-- Days of week for restocking: weekly -->
            <validation-provider
              name="Días de entrega"
              rules="required"
              v-slot="{ errors }"
              v-if="
                restock_schedule === 'weekly' || restock_schedule === 'monthly'
              "
              tag="div"
              class="my-2"
            >
              <div v-if="restock_schedule === 'weekly'">
                <b-form-group
                  label="Días de entrega (puedes seleccionar más de uno)"
                  label-for="weeklyDaysRestockSchedule"
                  class="custom-form-group"
                >
                  <v-select
                    v-model="automatic_restocking.days_of_week_for_restocking"
                    :options="days_of_week_for_restocking_weekly_options"
                    :reduce="(val) => val.type"
                    label="name"
                    multiple
                    :clearable="true"
                    placeholder="Selecciona los días de la semana o mes"
                  />
                </b-form-group>
              </div>

              <!-- Days of week for restocking: monthly -->
              <div v-if="restock_schedule === 'monthly'">
                <b-form-group
                  label="Días de entrega (puedes seleccionar más de uno)"
                  label-for="daysRestockSchedule"
                  class="custom-form-group"
                >
                  <v-select
                    v-model="automatic_restocking.days_of_week_for_restocking"
                    :options="days_of_week_for_restocking_monthly_options"
                    :reduce="(val) => val.type"
                    label="name"
                    multiple
                    :clearable="true"
                    placeholder="Selecciona los días de la semana o mes"
                  />
                </b-form-group>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <!-- Preferred delivery time -->
            <validation-provider
              name="Horario de entrega"
              rules="required"
              v-slot="{ errors }"
              v-if="restock_schedule"
            >
              <div v-if="restock_schedule">
                <b-form-group
                  label="¿Cuál es el mejor horario de entrega"
                  label-for="preferredDeliveryTime"
                  class="custom-form-group"
                >
                  <v-select
                    v-model="automatic_restocking.preferred_delivery_time"
                    :options="timeArray"
                    :reduce="(val) => val.type"
                    label="name"
                    clearable
                    placeholder="Selecciona el mejor horario para ti"
                  />
                </b-form-group>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </tab-content>

        <tab-content title="Tiendas" :before-change="validateStoreSelection">
          <b-row>
            <b-col>
              <div
                class="d-flex align-items-center mb-2 justify-content-between"
              >
                <h3>
                  Selecciona las tiendas de deseas que participen en el pedido
                  automático
                </h3>
              </div>

              <div class="d-flex align-items-center my-2">
                <Searchbar class="flex-1" @onChange="handleSearchStore" />

                <!-- <div class="d-flex align-items-center ml-2">
                        <feather-icon
                          v-if="postalCode"
                          class="cursor-pointer mr-50"
                          icon="XIcon"
                          size="24"
                          @click="reset"
                        />
        
                        <b-button
                          class="d-flex"
                          size="sm"
                          variant="outline-primary"
                          @click="handleChangePostalCode"
                        >
                          <feather-icon
                            icon="MapPinIcon"
                            v-b-toggle.store-product-filters
                            class="mr-50"
                          />
                          {{ postalCode ? postalCode : "Seleccionar" }}
                        </b-button>
                      </div> -->
              </div>

              <div class="d-block flex-wrap d-lg-flex">
                <ul class="mt-2 flex-1">
                  <li v-for="store in stores" :key="store.id" class="mb-1">
                    <b-form-checkbox
                      :value="store.id"
                      v-model="selectedStoresIds"
                      @change="handleSelectedStoresChange(store.id)"
                      class="d-flex align-items-center"
                    >
                      <b-img
                        :src="store.logo"
                        width="40"
                        class="mx-50 cursor-pointer"
                      />
                      <span class="cursor-pointer">
                        {{ store.name }}
                      </span>
                    </b-form-checkbox>
                  </li>
                </ul>

                <div class="my-2 flex-1">
                  <p>Tiendas seleccionadas: {{ selectedStores.length }}</p>

                  <ul class="mt-2">
                    <li
                      v-for="store in selectedStores"
                      :key="store.id"
                      class="mb-1"
                    >
                      <b-img
                        :src="store.logo"
                        width="40"
                        class="mx-50 cursor-pointer"
                      />
                      <span class="cursor-pointer">
                        {{ store.name }}
                      </span>

                      <feather-icon
                        icon="XIcon"
                        size="16"
                        class="ml-50"
                        v-if="selectedStoresIds.includes(store.id)"
                        @click.stop="handleSelectedStoresChange(store.id)"
                      />
                    </li>
                  </ul>
                </div>
              </div>

              <Pagination
                :handlePagination="handleStoresPagination"
                :pagination="storesPagination"
                :hideEntriesPerPage="true"
              />
            </b-col>
          </b-row>
        </tab-content>

        <tab-content title="Productos" :before-change="validateCart">
          <b-row>
            <b-col>
              <div
                class="d-flex align-items-center mb-2 justify-content-between"
              >
                <h3>
                  Selecciona los productos para pedir automáticamente al
                  proveedor
                </h3>

                <div class="d-flex align-items-center justify-content-end">
                  <b-button
                    class="mr-15 mb-1"
                    variant="primary"
                    pill
                    @click="handleCartModal"
                  >
                    <feather-icon
                      icon="ShoppingCartIcon"
                      size="16"
                      :badge="cartProductsCount"
                    />
                  </b-button>
                </div>
              </div>
              <div class="d-flex align-items-center mb-2">
                <div class="mb-2 flex-1">
                  <p>Buscar productos por nombre o código de barras</p>
                  <searchbar-qrcode @onChange="handleSearchbarChange" />
                </div>
              </div>

              <StoreProductList>
                <li
                  v-for="storeProduct in storeProducts"
                  :key="storeProduct.id"
                >
                  <StoreProductGrouped
                    :product="storeProduct"
                    :onAddToCart="handleAddToCart"
                    :validateInventory="false"
                    :rangePrice="true"
                  />
                  <div v-if="!storeProducts.length" class="w-100 text-center">
                    No hay resultados
                  </div>
                </li>
              </StoreProductList>

              <div
                class="d-flex justify-content-center py-4"
                v-if="storeProducts.length > 0"
              >
                <PaginationSimple
                  :pagination="storeProductsPagination"
                  :handlePagination="handleStoreProductsPagination"
                  :loading="loading"
                />
              </div>
            </b-col>
          </b-row>
        </tab-content>

        <tab-content title="Confirmar">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Resumen de pedido automático</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="4" class="my-2">
              <!-- <p>
                <small class="mb-1 mt-1"> Proveedor: </small>
                <small class="text-warning"> AQUI HAY QUE MODIFICAR </small>
              </p> -->

              <p>
                <small class="mb-1 mt-1"> Tipo de pedido: </small>
                <small class="text-warning">
                  {{ restockType }}
                </small>
              </p>

              <p>
                <small class="mb-1 mt-1"> Tipo de entrega: </small>
                <small class="text-warning">
                  {{ scheduleName }}
                </small>
              </p>

              <p>
                <small class="mb-0 mt-0"> Días de entrega: </small>
                <small class="text-warning">
                  {{ dayName }}
                </small>
              </p>
            </b-col>
            <b-col cols="12" sm="8" class="my-2">
              <b-table
                :items="restockingStoreProducts"
                responsive
                :fields="tableColumnsDetails"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
                class="position-relative"
              >
                <!-- Product name  -->
                <template #cell(name)="product">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="product.item.logo"
                        :text="avatarText(product.item.name)"
                        :variant="`light-primary`"
                      />
                    </template>
                    <b-link class="font-weight-bold d-block text-nowrap">
                      {{ product.item.name }}
                    </b-link>
                    <small class="text-muted">{{ product.item.variant }}</small>
                  </b-media>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import vSelect from "vue-select"
import { FormWizard, TabContent } from "vue-form-wizard"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { debounce } from "lodash"

import StoreProductGrouped from "@core/components/store-products/store-product-grouped.vue"
import StoreProductList from "@/@core/components/store-products/store-product-list.vue"
import BaseCropper from "@/@core/components/BaseCropper.vue"
import AddStoreProducts from "@/views/stores/AddStoreProductsToAutomaticRestockingSearchBar.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import RestockingProductsCart from "@/views/stores/RestockingProductsCart.vue"
import AutomaticRestockingReviewProducts from "@/@core/components/automatic-restocking/AutomaticRestockingReviewProducts.vue"
// import PostalCodeModal from "@/@core/components/PostalCodeModal.vue"
import PaginationSimple from "@/@core/components/PaginationSimple.vue"
import StoreProductInventoryModal from "@core/components/store-products/store-product-inventory-modal.vue"
import SearchbarQrcode from "@core/components/searchbar-qrcode.vue"
import StoreIcon from "@/components/icons/store-icon.vue"
import Searchbar from "@/@core/components/Searchbar.vue"

import { generateTimeArray } from "@core/utils/time-utils"

import "vue-form-wizard/dist/vue-form-wizard.min.css"
import "@/@core/scss/vue/libs/vue-wizard.scss"

import Cleave from "vue-cleave-component"
import { avatarText } from "@core/utils/filter"
import { handleAPIErrors } from "@/utils/fetch-utils"
import messagesMixin from "@/@core/mixins/messagesMixin"
import Cart from "@/@core/components/Cart.vue"
import Pagination from "@/@core/components/Pagination.vue"
import storeProducts from "@/store/storeProducts"

import { DATE_OPTIONS, DAYS_OF_WEEK } from "@/constants/date"
import { AUTOMATIC_RESTOCKING_TYPES } from "@/constants/restocking"

export default {
  components: {
    Searchbar,
    ValidationProvider,
    ValidationObserver,
    StoreIcon,
    FormWizard,
    StoreProductGrouped,
    TabContent,
    StoreProductList,
    // PostalCodeModal,
    Cart,
    PaginationSimple,
    StoreProductInventoryModal,
    SearchbarQrcode,
    Pagination,

    vSelect,
    Cleave,
    ToastificationContent,
    BaseCropper,
    AddStoreProducts,
    avatarText,
    RestockingProductsCart,
    AutomaticRestockingReviewProducts,
  },
  mixins: [messagesMixin],
  setup() {
    return {
      // Filter
      avatarText,
    }
  },

  data() {
    return {
      storeProducts: [],
      storeProductsPagination: {},
      selectedProduct: {},
      selectedStoreProduct: {},
      loading: false,
      selectedStores: [],
      selectedStoresIds: [],
      selectedStoreId: null,
      establishment: null,
      isSku: false,
      establishmentId: this.$route.params.id,
      searchQuery: null,
      // postalCode: localStorage.getItem("postalCode"),
      stores: [],
      storesPagination: {
        page: 1,
      },
      ownStores: [
        {
          id: null,
          name: "No",
        },
      ],
      deliveryOption: null,
      automatic_restock_type_options: AUTOMATIC_RESTOCKING_TYPES,
      restock_schedule_type_options: DATE_OPTIONS,
      days_of_week_for_restocking_weekly_options: DAYS_OF_WEEK,
      days_of_week_for_restocking_monthly_options: Array.from(
        { length: 30 },
        (_, i) => ({
          type: (i + 1).toString(),
          name: `Dia ${i + 1} del mes`,
        })
      ),
      timeArray: [],
      warehouses: [],
      restock_schedule: "weekly",
      automatic_restocking: {
        establishment_id: this.$route.params.id,
        restock_schedule: null,
        automatic_restock_type: "by_date",
        buy_from_store: null,
        days_of_week_for_restocking: ["monday"],
        preferred_delivery_time: "2:00 PM",
        restocking_store_products_attributes: [
          {
            store_product_id: null,
            threshold_inventory: null,
            minimum_stock: null,
          },
        ],
      },
      wantOwnStore: false,
      warehouseOptions: [
        {
          id: true,
          name: "Si, deseo comprarle a mi almacén",
        },
        {
          id: false,
          name: "No",
        },
      ],
      tableColumnsDetails: [
        {
          key: "name",
          label: "Producto",
        },
        {
          key: "threshold_inventory",
          label: "Inventario deseado",
        },
        {
          key: "minimum_stock",
          label: "Inventario mínimo",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("automaticRestocking", ["restockingStoreProducts"]),
    ...mapGetters("cart", ["cart", "cartProductsCount"]),

    coordinates() {
      if (!this.selectedStores) {
        return []
      }

      return this.selectedStores
        .map((store) => {
          const address = store.address_attributes

          if (!address) {
            return null
          }

          return {
            lat: address.lat,
            lng: address.lng,
          }
        })
        .filter(Boolean)
    },

    storesIds() {
      if (!this.stores || this.stores.length === 0) {
        return []
      }

      return this.stores.map((store) => store.id)
    },

    restockType() {
      const restockType = this.automatic_restocking.automatic_restock_type
      const found = this.automatic_restock_type_options.find(
        (option) => option.type === restockType
      )
      return found ? found.name : "No encontrado"
    },

    scheduleName() {
      const scheduleType = this.restock_schedule
      const found = this.restock_schedule_type_options.find(
        (option) => option.type === scheduleType
      )
      return found ? found.name : "No encontrado"
    },

    dayName() {
      const days = this.automatic_restocking.days_of_week_for_restocking
      let found = days.map((day) =>
        this.days_of_week_for_restocking_weekly_options.find(
          (option) => option.type.toLowerCase() === day.toLowerCase()
        )
      )
      const names = found.map((item) => (item ? item.name : "No encontrado"))
      return names.join(", ")
    },
  },
  watch: {
    restock_schedule() {
      this.automatic_restocking.days_of_week_for_restocking = []
    },
  },
  async mounted() {
    this.timeArray = generateTimeArray()

    this.getStores()

    this.fetchShortStores({
      by_delivery_option: "delivery",
      by_category: "online",
      by_store_product_count: true,
      by_establishment: this.establishmentId,
      by_store_type: "warehouse",
      meta: {
        pagination: {
          per_page: 1000,
        },
      },
    }).then((res) => {
      this.warehouses = res.data
    })
  },
  methods: {
    ...mapActions("automaticRestocking", ["createAutomaticRestocking"]),
    ...mapMutations("automaticRestocking", [
      "clearRestockingStoreProducts",
      "pushRestockingStoreProduct",
    ]),
    ...mapActions("storeProducts", ["fetchGroupedStoreProducts"]),
    ...mapMutations("cart", ["editCartItemQuantity"]),
    ...mapActions("cart", ["emptyCart", "addCartItem"]),
    ...mapActions("stores", ["fetchShortStores"]),

    handleSelectedStoresChange(storeId) {
      const store = this.stores.find((store) => store.id === storeId)
      if (!store) return

      const isSelected = this.selectedStores.some(({ id }) => id === storeId)

      if (isSelected) {
        this.selectedStores = this.selectedStores.filter(
          ({ id }) => id !== storeId
        )
        this.selectedStoresIds = this.selectedStoresIds.filter(
          (id) => id !== storeId
        )
      } else {
        this.selectedStores.push(store)
      }
    },

    reset() {
      // this.postalCode = null
      this.stores = []
      this.storesPagination = {
        page: 1,
      }
      this.getPublicStoreProducts()
    },

    handleSearchbarChange: debounce(async function (query) {
      if (!query || query === "") {
        this.isSku = false
        this.searchQuery = null

        await this.getPublicStoreProducts()
        return
      }

      if (/^\d*$/.test(query)) {
        const barcodeWithOutLastDigit = query.substring(0, query.length - 1)

        this.isSku = true
        this.searchQuery = Number(barcodeWithOutLastDigit)
      } else {
        this.searchQuery = query
      }

      await this.getPublicStoreProducts()
    }, 250),

    async handleStoreProductsPagination({ page, per_page }) {
      await this.getPublicStoreProducts(
        {},
        {
          page,
        }
      )
      window.scrollTo(0, 0)
    },

    handleAddToCart({
      product,
      store_product,
      skipInventory = false,
      minimumStock,
      thresholdInventory,
    }) {
      // if (!this.postalCode) {
      //   this.$bvModal.show("ask-postal-code-modal")
      //   return
      // }

      if (!skipInventory) {
        this.selectedProduct = product
        this.selectedStoreProduct = store_product
        this.$bvModal.show("store-product-inventory-modal")
        return
      }

      const newRestockingStoreProduct = {
        minimum_stock: minimumStock,
        threshold_inventory: thresholdInventory,
        store_product_id: store_product.id,
        logo: product.logo,
      }

      this.pushRestockingStoreProduct(newRestockingStoreProduct)

      const newCartProduct = {
        ...store_product,
        product_attributes: {
          ...product,
          store_products: undefined,
        },
        units: 1,
      }
      this.editCartItemQuantity({ cartItem: newCartProduct, units: 0 })
      this.addCartItem(newCartProduct)
    },

    handleCartModal() {
      if (this.cartProductsCount) {
        this.$bvModal.show("cart-modal")
      } else {
        this.errorSwal({
          text: "No hay productos en el carrito",
        })
      }
    },

    // async handlePostalCodeSubmit(newPostalCode) {
    //   this.emptyCart()

    //   try {
    //     this.postalCode = newPostalCode

    //     this.$swal({
    //       title: "Cargando tiendas cercanas",
    //       allowOutsideClick: false,
    //       didOpen: () => {
    //         this.$swal.showLoading()
    //       },
    //     })

    //     await this.getStores()
    //     await this.getPublicStoreProducts()
    //   } catch (e) {
    //     const errors = handleAPIErrors(e.response?.data)

    //     if (errors && errors.length) {
    //       this.showErrorToast("Error", errors[0])
    //       return
    //     }

    //     if (e.message) {
    //       this.showErrorToast("Error", e.message)
    //       return
    //     }

    //     this.showErrorToast("Error", "Lo sentimos, ocurrio un error")
    //     return
    //   } finally {
    //     this.selectedProduct = null
    //     this.selectedStoreProduct = null
    //     this.$swal.close()
    //   }
    // },

    async getStores({ query, page = 1 } = {}) {
      try {
        this.loading = true
        const storesResponse = await this.fetchShortStores({
          by_establishment: this.establishmentId,
          by_establishment_status: "active",
          by_name: query,
          meta: {
            pagination: {
              per_page: 10,
              page: page || 1,
            },
          },
        })

        this.stores = storesResponse.data
        this.storesPagination = storesResponse.meta.pagination
      } catch (e) {
        const errors = handleAPIErrors(e.response?.data)
        this.errorToast(errors[0])
      } finally {
        this.loading = false
      }
    },

    handleSearchStore(query) {
      this.getStores({ query })
    },

    handleStoresPagination({ page }) {
      this.getStores({ page })
    },

    async getPublicStoreProducts(filters, { page = 1 } = {}) {
      this.loading = true
      const queryValid = this.searchQuery && this.searchQuery.length > 0

      try {
        const res = await this.fetchGroupedStoreProducts({
          ...filters,
          by_active_status: "true",
          by_store_delivery: "delivery",
          by_store_category: "online",
          by_store: this.selectedStoreId,
          by_sku: this.isSku && queryValid ? this.searchQuery : null,
          by_name: !this.isSku || queryValid ? this.searchQuery : null,
          by_coordinates: this.selectedStoreId ? null : this.coordinates,
          meta: {
            pagination: { page },
          },
        })

        this.storeProducts = res.data
        this.storeProductsPagination = res.meta.pagination

        return res
      } catch (e) {
        const errors = handleAPIErrors(e.response?.data)

        if (errors && errors.length) {
          this.errorToast(errors[0])
          return
        }

        this.errorToast("Lo sentimos, ocurrio un error")
      } finally {
        this.loading = false
      }
    },

    // handleChangePostalCode() {
    //   this.selectedProduct = null
    //   this.selectedStoreProduct = null
    //   this.$bvModal.show("ask-postal-code-modal")
    // },

    formSubmitted() {
      this.automatic_restocking.restock_schedule = this.restock_schedule
      this.automatic_restocking.restocking_store_products_attributes =
        this.restockingStoreProducts
      this.automatic_restocking.buy_from_store = this.selectedStoreId
      this.automatic_restocking.store_ids = this.selectedStoresIds

      this.createAutomaticRestocking(this.automatic_restocking)
        .then((response) => {
          this.successSwal({
            text: "Pedido automático creado con exito!",
            title: "Pedido automático creado con exito!",
          })

          this.$router.push({
            name: "automatic-restocking-view",
            params: { id: this.$route.params.id },
          })
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response?.data)
          this.errorToast(errors[0])
        })
    },
    validateRestock() {
      return new Promise((resolve, reject) => {
        this.$refs.restockRules.validate().then(async (success) => {
          if (success) {
            resolve(true)
          } else {
            this.errorToast("Error", "Selecciona el tipo de pedido")

            reject()
          }
        })
      })
    },
    validateCart() {
      return new Promise((resolve, reject) => {
        if (this.cartProductsCount > 0) {
          resolve(true)
        } else {
          this.errorToast("Error", "No hay productos seleccionados")
          reject()
        }
      })
    },
    validateStoreSelection() {
      return new Promise(async (resolve, reject) => {
        if (this.selectedStoresIds.length > 0) {
          await this.getPublicStoreProducts()

          resolve(true)
        } else {
          this.errorToast("Error", "Selecciona al menos un tienda")
          reject()
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.promo-card {
  height: 120px;
}

.custom-form-group {
  width: 60%; /* Set the desired width for the input fields */
}

.vue-form-wizard {
  .wizard-header {
    display: none;
  }
  ul.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }
}

.btn-size {
  width: 100%;
  height: 60px;
  margin-right: 40px; /* Adjust the margin as needed */
  transition: background-color 0.7s ease; /* Smooth transition for color change */
}

.btn-size.active {
  background-color: #28a745 !important; /* Green color for the success variant */
}
</style>
