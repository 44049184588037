<template>
  <b-modal :id="id" title="Detalles del producto" hide-footer @hidden="reset">
    <b-badge
      pill
      :variant="'light-warning'"
      class="d-inline-block truncate text-wrap"
      style="max-width: 100%"
    >
      {{ "¿Cuál es la cantidad óptima que deseas tener en inventario?" }}
    </b-badge>
    <validation-observer
      ref="restockingStoreProductInfo"
      tag="form"
      v-slot="{ invalid }"
    >
      <validation-provider
        #default="{ errors }"
        name="La cantidad que se desea tener es requerida"
        rules="required|numeric"
      >
        <b-input
          v-model="threshold_inventory"
          :state="errors.length > 0 ? false : null"
          placeholder="20"
          size="lg"
        >
        </b-input>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <div class="text-wrap mt-1 mb-50">
        <b-badge
          pill
          variant="light-warning"
          class="d-inline-block truncate text-wrap"
        >
          {{ "¿Cuál es la cantidad mínima que deseas tener?" }}
        </b-badge>
      </div>

      <validation-provider
        #default="{ errors }"
        name="La cantidad mínima que se desea tener es requerida"
        rules="required|numeric"
      >
        <b-input
          v-model="minimum_stock"
          :state="errors.length > 0 ? false : null"
          placeholder="5"
          size="lg"
        >
        </b-input>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <small
        class="text-danger"
        v-if="parseInt(minimum_stock) > parseInt(threshold_inventory)"
      >
        El mínimo de stock no puede ser mayor al umbral de inventario
      </small>

      <div class="d-flex mt-2 justify-content-end">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          class="mr-1"
          @click="$bvModal.hide(id)"
        >
          Cancelar
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          type="button"
          @click="handleSubmit()"
          :disabled="
            invalid || parseInt(minimum_stock) > parseInt(threshold_inventory)
          "
        >
          Continuar
        </b-button>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: "store-product-inventory-modal",
    },
    storeProduct: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      minimum_stock: null,
      threshold_inventory: null,
    }
  },
  methods: {
    reset() {
      this.minimum_stock = null
      this.threshold_inventory = null
    },
    handleSubmit() {
      this.$bvModal.hide(this.id)
      this.$emit("onSubmit", {
        minimumStock: this.minimum_stock,
        thresholdInventory: this.threshold_inventory,
        store_product: this.storeProduct,
        product: this.storeProduct?.product_attributes || this.product,
        skipInventory: true,
      })
    },
  },
}
</script>
