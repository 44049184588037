export const DATE_OPTIONS = [
  { type: "weekly", name: "Semanal" },
  { type: "monthly", name: "Mensual" },
]

export const DAYS_OF_WEEK = [
  { type: "monday", name: "Lunes" },
  { type: "tuesday", name: "Martes" },
  { type: "wednesday", name: "Miercoles" },
  { type: "thursday", name: "Jueves" },
  { type: "friday", name: "Viernes" },
  { type: "saturday", name: "Sabado" },
  { type: "sunday", name: "Domingo" },
]
